/* eslint-disable @typescript-eslint/no-explicit-any */
import { useIsUserCompany, useUserAddons, useUserPermissions } from '@/features/auth'
import { AppRoute } from '@/routes/router'
import { handleCatchLazyImportPage } from '@/utils/lazy-import'
import { lazy } from 'react'
const HomePage = lazy(() =>
  import('@/pages/Home').then(module => module as any).catch(handleCatchLazyImportPage)
)

// StakeHolder
const StakeHoldersListPage = lazy(() =>
  import('@/pages/stakeHolders/StakeHoldersList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderCreationManualPage = lazy(() =>
  import('@/pages/stakeHolders/StakeHolderCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderCreationFilePage = lazy(() =>
  import('@/pages/stakeHolders/StakeHolderCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderEditionPage = lazy(() =>
  import('@/pages/stakeHolders/StakeHolderEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// StakeHolderProduct
const StakeHolderProductsListPage = lazy(() =>
  import('@/pages/stakeHolderProducts/StakeHolderProductsList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderProductCreationManualPage = lazy(() =>
  import('@/pages/stakeHolderProducts/StakeHolderProductCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderProductCreationFilePage = lazy(() =>
  import('@/pages/stakeHolderProducts/StakeHolderProductCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const StakeHolderProductEditionPage = lazy(() =>
  import('@/pages/stakeHolderProducts/StakeHolderProductEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Categories
const CategoriesListPage = lazy(() =>
  import('@/pages/categories/CategoryList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const CategoryCreationManualPage = lazy(() =>
  import('@/pages/categories/CategoryCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const CategoryEditionPage = lazy(() =>
  import('@/pages/categories/CategoryEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const CategoryCreationFilePage = lazy(() =>
  import('@/pages/categories/CategoryCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Reception
const ReceptionCreationManualPage = lazy(() =>
  import('@/pages/receptions/ReceptionCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ReceptionCreationFilePage = lazy(() =>
  import('@/pages/receptions/ReceptionCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ReceptionEditionPage = lazy(() =>
  import('@/pages/receptions/ReceptionEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Metadata Template
const MetadataTemplatesListPage = lazy(() =>
  import('@/pages/metadataTemplates/MetadataTemplatesList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const MetadataTemplateCreationPage = lazy(() =>
  import('@/pages/metadataTemplates/MetadataTemplateCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const MetadataTemplateEditionPage = lazy(() =>
  import('@/pages/metadataTemplates/MetadataTemplateEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Product
const ProductsListPage = lazy(() =>
  import('@/pages/products/ProductsList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ProductCreationManualPage = lazy(() =>
  import('@/pages/products/ProductCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ProductCreationFilePage = lazy(() =>
  import('@/pages/products/ProductCreationFile')
    .then(module => ({ default: module.ProductCreationFilePage } as any))
    .catch(handleCatchLazyImportPage)
)
const ProductEditionPage = lazy(() =>
  import('@/pages/products/ProductEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// TRU
const TRUsListPage = lazy(() =>
  import('@/pages/trus/TRUsList').then(module => module as any).catch(handleCatchLazyImportPage)
)
const TRUCreationManualPage = lazy(() =>
  import('@/pages/trus/TRUCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TRUMetadataPage = lazy(() =>
  import('@/pages/trus/TRUMetadata').then(module => module as any).catch(handleCatchLazyImportPage)
)
const TRUCreationFilePage = lazy(() =>
  import('@/pages/trus/TRUCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TRUShipmentManualPage = lazy(() =>
  import('@/pages/trus/TRUShipmentManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TRUShipmentCreationFilePage = lazy(() =>
  import('@/pages/trus/TRUShipmentCreationFile')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TRUEditionPage = lazy(() =>
  import('@/pages/trus/TRUEdition').then(module => module as any).catch(handleCatchLazyImportPage)
)

// Shipments
const ShipmentsListPage = lazy(() =>
  import('@/pages/shipments/ShipmentsListPage')
    .then(module => ({ default: module.ShipmentListPage } as any))
    .catch(handleCatchLazyImportPage)
)

// Transparency
const QRCodesListPage = lazy(() =>
  import('@/pages/transparency/QRCodesList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const QRCodeCreatePage = lazy(() =>
  import('@/pages/transparency/QRCodeCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const QRCodeEditPage = lazy(() =>
  import('@/pages/transparency/QRCodeEdit')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const QRCodeStatisticsPage = lazy(() =>
  import('@/pages/transparency/QRStatistics')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const QRCodesLandingPage = lazy(() =>
  import('@/pages/transparency/QRCodesLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Traceability
const TraceabilityCreatePage = lazy(() =>
  import('@/pages/traceability/TraceabilityCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TraceabilityViewPage = lazy(() =>
  import('@/pages/traceability/TraceabilityView')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Reports
const ReportCreatePage = lazy(() =>
  import('@/pages/reports/ReportCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ReportViewPage = lazy(() =>
  import('@/pages/reports/ReportView')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ReportViewDeliveryNote = lazy(() =>
  import('@/pages/reports/ReportViewDeliveryNote')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const ReportViewStockReport = lazy(() =>
  import('@/pages/reports/ReportViewStockReport')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const ReportViewCustomReport = lazy(() =>
  import('@/pages/reports/ReportViewCustomReport')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Documents
const DocumentsViewPage = lazy(() =>
  import('@/pages/documents/DocumentsView')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DocumentFolderCreatePage = lazy(() =>
  import('@/pages/documents/DocumentFolderCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DocumentFileCreatePage = lazy(() =>
  import('@/pages/documents/DocumentFileCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DocumentFolderEditPage = lazy(() =>
  import('@/pages/documents/DocumentFolderEdit')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DocumentFileEditPage = lazy(() =>
  import('@/pages/documents/DocumentFileEdit')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DocumentValidationRequestCreatePage = lazy(() =>
  import('@/pages/documents/DocumentValidationRequestCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// UploadRequest
const UploadRequestCreatePage = lazy(() =>
  import('@/pages/documents/UploadRequestCreate')
    .then(module => ({ default: module.UploadRequestCreatePage } as any))
    .catch(handleCatchLazyImportPage)
)

//  Facilities
const FacilitiesListPage = lazy(() =>
  import('@/pages/facilities/FacilitiesList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const FacilitiesCreatePage = lazy(() =>
  import('@/pages/facilities/FacilityCreationManual')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const FacilitiesCreateFilePage = lazy(() =>
  import('@/pages/facilities/FacilityCreationFile')
    .then(module => ({ default: module.FacilityCreationFilePage } as any))
    .catch(handleCatchLazyImportPage)
)

const FacilitiesEditionPage = lazy(() =>
  import('@/pages/facilities/FacilityEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Profile
const ProfilePage = lazy(() =>
  import('@/pages/Profile').then(module => module as any).catch(handleCatchLazyImportPage)
)

// Departments
const DepartmentsListPage = lazy(() =>
  import('@/pages/departments/DepartmentsList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DepartmentCreationPage = lazy(() =>
  import('@/pages/departments/DepartmentCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DepartmentEditPage = lazy(() =>
  import('@/pages/departments/DepartamentEdit')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DepartmentsLandingPage = lazy(() =>
  import('@/pages/departments/DepartmentsLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Forms
const FormsPage = lazy(() =>
  import('@/pages/forms/FormsList').then(module => module as any).catch(handleCatchLazyImportPage)
)

const FormCreationPage = lazy(() =>
  import('@/pages/forms/FormCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const FormEditionPage = lazy(() =>
  import('@/pages/forms/FormEdition').then(module => module as any).catch(handleCatchLazyImportPage)
)
const FormsLandingPage = lazy(() =>
  import('@/pages/forms/FormsLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Tasks
const TasksPage = lazy(() =>
  import('@/pages/tasks/TasksList').then(module => module as any).catch(handleCatchLazyImportPage)
)

const TaskCreationPage = lazy(() =>
  import('@/pages/tasks/TaskCreation')
    .then(module => ({ default: module.TaskCreationPage } as any))
    .catch(handleCatchLazyImportPage)
)

const TaskEditionPage = lazy(() =>
  import('@/pages/tasks/TaskEdition')
    .then(module => ({ default: module.TaskEditionPage } as any))
    .catch(handleCatchLazyImportPage)
)

const TaskViewPage = lazy(() =>
  import('@/pages/tasks/TaskView')
    .then(module => ({ default: module.TaskViewPage } as any))
    .catch(handleCatchLazyImportPage)
)

const TaskBulkEditionPage = lazy(() =>
  import('@/pages/tasks/TaskBulkEdition').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)
const TaskValidatePage = lazy(() =>
  import('@/pages/tasks/TaskValidate').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)
const TaskBulkValidatePage = lazy(() =>
  import('@/pages/tasks/TaskBulkValidate').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)
const TasksLandingPage = lazy(() =>
  import('@/pages/tasks/TasksLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const TaskValidationRequestCreatePage = lazy(() =>
  import('@/pages/tasks/TaskValidationRequestCreate')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Dashboards
const DashboardsListPage = lazy(() =>
  import('@/pages/dashboards/DashboardsList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DashboardCreationPage = lazy(() =>
  import('@/pages/dashboards/DashboardCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DashboardEditionPage = lazy(() =>
  import('@/pages/dashboards/DashboardEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DashboardViewPage = lazy(() =>
  import('@/pages/dashboards/DashboardView')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const DashboardsLandingPage = lazy(() =>
  import('@/pages/dashboards/DashboardsLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// Charts
const ChartCreationPage = lazy(() =>
  import('@/pages/charts/ChartCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ChartCreationBulkPage = lazy(() =>
  import('@/pages/charts/ChartCreationBulk')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ChartEditionPage = lazy(() =>
  import('@/pages/charts/ChartEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)
const ChartViewPage = lazy(() =>
  import('@/pages/charts/ChartView').then(module => module as any).catch(handleCatchLazyImportPage)
)

// Compliances
const ComplianceValidatePage = lazy(() =>
  import('@/pages/compliances/ComplianceValidatePage').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)

const ComplianceEditPage = lazy(() =>
  import('@/pages/compliances/ComplianceEditPage').catch(() => {
    window.location.reload()
    return { default: () => null as unknown as JSX.Element }
  })
)

// Automations
const AutomationsListPage = lazy(() =>
  import('@/pages/automations/AutomationsList')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const AutomationsLandingPage = lazy(() =>
  import('@/pages/automations/AutomationsLanding')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const AutomationCreationPage = lazy(() =>
  import('@/pages/automations/AutomationCreation')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

const AutomationEditionPage = lazy(() =>
  import('@/pages/automations/AutomationEdition')
    .then(module => module as any)
    .catch(handleCatchLazyImportPage)
)

// FOOD ALERTS

const RasffViewPage = lazy(() =>
  import('@/pages/foodAlerts/RasffViewPage')
    .then(module => ({ default: module.RasffViewPage } as any))
    .catch(handleCatchLazyImportPage)
)

// SUPPORT

const SupportPage = lazy(() =>
  import('@/pages/support/SupportPage')
    .then(module => ({ default: module.SupportPage } as any))
    .catch(handleCatchLazyImportPage)
)

export const useDashboardRoutes = (): AppRoute[] => {
  const userAddons = useUserAddons()
  const userPermissions = useUserPermissions()
  const isUserCompany = useIsUserCompany()
  // https://trazable.atlassian.net/browse/PRD-2269
  // USE CORRECT RAW MATERIAL PAGES BASED ON FLAG STATE
  const flagProductCategory = false // useFeatureFlag('product_categories')

  /**
   * Define all the dashboard routes
   */
  const routes = [
    // Profile
    {
      exact: true,
      path: '/profile',
      Page: ProfilePage,
    },
  ]

  if (isUserCompany || userPermissions.tasks.admin) {
    routes.push({
      exact: true,
      path: '/home',
      Page: HomePage,
    })
  }

  // StakeHolder
  if (userPermissions?.stakeHolders.read || userPermissions?.stakeHolders.write) {
    routes.push({
      exact: true,
      path: '/stakeholders',
      Page: StakeHoldersListPage,
    })

    if (userPermissions?.stakeHolders.write) {
      routes.push(
        {
          exact: true,
          path: '/stakeholders/create',
          Page: StakeHolderCreationManualPage,
        },
        {
          exact: true,
          path: '/stakeholders/create/file',
          Page: StakeHolderCreationFilePage,
        },
        {
          exact: true,
          path: '/stakeholders/:stakeHolderId/edit',
          Page: StakeHolderEditionPage,
        }
      )
    }
  }

  // StakeHolderProduct
  if (userPermissions?.stakeHolderProducts.read || userPermissions?.stakeHolderProducts.write) {
    routes.push({
      exact: true,
      path: '/stakeholder-products',
      Page: flagProductCategory ? CategoriesListPage : StakeHolderProductsListPage,
    })
    if (userPermissions?.stakeHolderProducts.write) {
      routes.push(
        {
          exact: true,
          path: '/stakeholder-products',
          Page: flagProductCategory ? CategoriesListPage : StakeHolderProductsListPage, // TODO: PRD-2269, USE CORRECT PAGE
        },
        {
          exact: true,
          path: '/stakeholder-products/create',
          Page: flagProductCategory // TODO: PRD-2269, USE CORRECT PAGE
            ? CategoryCreationManualPage
            : StakeHolderProductCreationManualPage,
        },
        {
          exact: true,
          path: '/stakeholder-products/create/file',
          Page: flagProductCategory ? CategoryCreationFilePage : StakeHolderProductCreationFilePage, // TODO: PRD-2269, USE CORRECT PAGE
        },
        {
          exact: false,
          path: `/stakeholder-products/:${
            flagProductCategory ? 'categoryId' : 'stakeHolderProductId'
          }/edit`,
          Page: flagProductCategory ? CategoryEditionPage : StakeHolderProductEditionPage, // TODO: PRD-2269, USE CORRECT PAGE
        }
      )
    }
  }

  // Product
  if (userPermissions?.products.read || userPermissions?.products.write) {
    routes.push({
      exact: true,
      path: '/products',
      Page: ProductsListPage,
    })
    if (userPermissions?.products.write) {
      routes.push(
        {
          exact: true,
          path: '/products/create',
          Page: ProductCreationManualPage,
        },
        {
          exact: true,
          path: '/products/create/file',
          Page: ProductCreationFilePage,
        },
        {
          exact: false,
          path: '/products/:productId/edit',
          Page: ProductEditionPage,
        }
      )
    }
  }

  // MetadataTemplate
  if (userPermissions?.metadataTemplates.read || userPermissions?.metadataTemplates.write) {
    routes.push({
      exact: true,
      path: '/metadata-templates',
      Page: MetadataTemplatesListPage,
    })
    if (userPermissions?.metadataTemplates.write) {
      routes.push(
        {
          exact: true,
          path: '/metadata-templates/create',
          Page: MetadataTemplateCreationPage,
        },
        {
          exact: true,
          path: '/metadata-templates/:metadataTemplateId/edit',
          Page: MetadataTemplateEditionPage,
        }
      )
    }
  }

  // TRUs
  if (userPermissions?.trus.read || userPermissions?.trus.write) {
    routes.push({
      exact: true,
      path: '/trus',
      Page: TRUsListPage,
    })
    if (userPermissions?.trus.write) {
      routes.push(
        {
          exact: true,
          path: '/receptions/create',
          Page: ReceptionCreationManualPage,
        },
        {
          exact: true,
          path: '/receptions/create/file',
          Page: ReceptionCreationFilePage,
        },
        {
          exact: true,
          path: '/receptions/:receptionId/edit',
          Page: ReceptionEditionPage,
        },

        {
          exact: true,
          path: '/trus/create',
          Page: TRUCreationManualPage,
        },
        {
          exact: true,
          path: '/trus/create/file',
          Page: TRUCreationFilePage,
        },
        {
          exact: true,
          path: '/trus/shipment',
          Page: TRUShipmentManualPage,
        },
        {
          exact: true,
          path: '/trus/shipments/file',
          Page: TRUShipmentCreationFilePage,
        },
        {
          exact: true,
          path: '/trus/:truId/edit',
          Page: TRUEditionPage,
        },
        {
          exact: true,
          path: '/trus/:truId/metadata',
          Page: TRUMetadataPage,
        }
      )
    }
  }

  // Shipments
  if (userPermissions?.trus.read || userPermissions?.trus.write) {
    routes.push({
      exact: true,
      path: '/shipments',
      Page: ShipmentsListPage,
    })
  }

  // Transparency
  if (userPermissions?.qr.read || userPermissions?.qr.write) {
    if (userAddons?.transparency.enabled) {
      routes.push({
        exact: true,
        path: '/transparency/qr-codes',
        Page: QRCodesListPage,
      })

      if (userPermissions?.qr.write) {
        routes.push(
          {
            exact: true,
            path: '/transparency/qr-codes/create',
            Page: QRCodeCreatePage,
          },
          {
            exact: true,
            path: '/transparency/qr-codes/:qrCodeId/edit',
            Page: QRCodeEditPage,
          },
          {
            exact: true,
            path: '/transparency/qr-codes/:qrCodeId/statistics',
            Page: QRCodeStatisticsPage,
          }
        )
      }
    } else {
      routes.push({
        exact: true,
        path: '/transparency/qr-codes',
        Page: QRCodesLandingPage,
      })
    }
  }

  // Traceability
  if (userPermissions?.traceability.read || userPermissions?.traceability.write) {
    routes.push(
      {
        exact: true,
        path: '/traceability/:truId',
        Page: TraceabilityViewPage,
      },
      {
        exact: true,
        path: '/traceability',
        Page: TraceabilityCreatePage,
      }
    )
  }

  // Reports
  if (userPermissions?.reports.write) {
    routes.push(
      {
        exact: true,
        path: '/reports/create',
        Page: ReportCreatePage,
      },
      {
        exact: true,
        path: '/reports/delivery-note',
        Page: ReportViewDeliveryNote,
      },
      {
        exact: true,
        path: '/reports/stock-report',
        Page: ReportViewStockReport,
      },
      {
        exact: true,
        path: '/reports/custom-report',
        Page: ReportViewCustomReport,
      },
      {
        exact: true,
        path: '/reports/:truId',
        Page: ReportViewPage,
      }
    )
  }

  // Documents
  if (userPermissions?.documents.read || userPermissions?.documents.write) {
    routes.push(
      {
        exact: true,
        path: '/documents',
        Page: DocumentsViewPage,
      },
      {
        exact: true,
        path: '/documents/upload-requests',
        Page: UploadRequestCreatePage,
      }
    )

    if (userPermissions?.documents.write) {
      routes.push(
        {
          exact: true,
          path: '/documents/folders/create',
          Page: DocumentFolderCreatePage,
        },
        {
          exact: true,
          path: '/documents/files/create',
          Page: DocumentFileCreatePage,
        },
        {
          exact: true,
          path: '/documents/folders/:folderId/edit',
          Page: DocumentFolderEditPage,
        },
        {
          exact: true,
          path: '/documents/files/:fileId/edit',
          Page: DocumentFileEditPage,
        }
      )

      const facilityRoutes = [
        {
          exact: true,
          path: '/facilities',
          Page: FacilitiesListPage,
        },
        {
          exact: true,
          path: '/facilities/create',
          Page: FacilitiesCreatePage,
        },
        {
          exact: true,
          path: '/facilities/create/file',
          Page: FacilitiesCreateFilePage,
        },
        {
          exact: true,
          path: '/facilities/:facilityId/edit',
          Page: FacilitiesEditionPage,
        },
      ]

      routes.push(...facilityRoutes)

      if (userAddons?.departments.enabled) {
        routes.push({
          exact: true,
          path: '/documents/files/:fileId/validation-requests/create',
          Page: DocumentValidationRequestCreatePage,
        })
      }
    }
  }

  // Departments
  if (userPermissions?.departments.read || userPermissions?.departments.write) {
    if (userAddons?.departments.enabled) {
      routes.push({
        exact: true,
        path: '/departments',
        Page: DepartmentsListPage,
      })
      if (userPermissions?.departments.write) {
        routes.push(
          {
            exact: true,
            path: '/departments/create',
            Page: DepartmentCreationPage,
          },
          {
            exact: false,
            path: '/departments/:departmentId/edit',
            Page: DepartmentEditPage,
          }
        )
      }
    } else {
      routes.push({
        exact: true,
        path: '/departments',
        Page: DepartmentsLandingPage,
      })
    }
  }

  // Forms
  if (userPermissions?.forms.read || userPermissions?.forms.write) {
    if (userAddons?.registries.enabled) {
      routes.push({
        exact: true,
        path: '/forms',
        Page: FormsPage,
      })
      if (userPermissions?.forms.write) {
        routes.push(
          {
            exact: true,
            path: '/forms/create',
            Page: FormCreationPage,
          },
          {
            exact: true,
            path: '/forms/:formId/edit',
            Page: FormEditionPage,
          }
        )
      }
    } else {
      routes.push({
        exact: true,
        path: '/forms',
        Page: FormsLandingPage,
      })
    }
  }

  // Tasks
  if (userPermissions?.tasks.read || userPermissions?.tasks.write) {
    if (userAddons?.registries.enabled) {
      routes.push(
        {
          exact: true,
          path: '/tasks',
          Page: TasksPage,
        },
        {
          exact: true,
          path: '/tasks/create',
          Page: TaskCreationPage,
        },
        {
          exact: true,
          path: '/tasks/:taskId/edit',
          Page: TaskEditionPage,
        },
        {
          exact: true,
          path: '/tasks/:taskId/view',
          Page: TaskViewPage,
        },
        {
          exact: true,
          path: '/tasks/:taskId/validate',
          Page: TaskValidatePage,
        },
        {
          exact: true,
          path: '/tasks/validation-requests/create',
          Page: TaskValidationRequestCreatePage,
        },
        {
          exact: true,
          path: '/tasks/bulk-edit',
          Page: TaskBulkEditionPage,
        },
        {
          exact: true,
          path: '/tasks/bulk-validate',
          Page: TaskBulkValidatePage,
        },
        {
          exact: true,
          path: '/compliances/:complianceId/validate',
          Page: ComplianceValidatePage,
        },
        {
          exact: true,
          path: '/compliances/:complianceId/edit',
          Page: ComplianceEditPage,
        }
      )
    } else {
      routes.push({
        exact: true,
        path: '/tasks',
        Page: TasksLandingPage,
      })
    }
  }

  // Dashboards
  if (userPermissions?.dashboards.read || userPermissions?.dashboards.write) {
    if (userAddons?.registries.enabled) {
      if (userPermissions?.dashboards.write) {
        routes.push(
          {
            exact: true,
            path: '/dashboards/create',
            Page: DashboardCreationPage,
          },
          {
            exact: true,
            path: '/dashboards/:dashboardId/edit',
            Page: DashboardEditionPage,
          },
          {
            exact: true,
            path: '/dashboards/:dashboardId/charts/create',
            Page: ChartCreationPage,
          },
          {
            exact: true,
            path: '/dashboards/:dashboardId/charts/create-bulk',
            Page: ChartCreationBulkPage,
          },
          {
            exact: true,
            path: '/dashboards/:dashboardId/charts/:chartId',
            Page: ChartViewPage,
          },
          {
            exact: true,
            path: '/dashboards/:dashboardId/charts/:chartId/edit',
            Page: ChartEditionPage,
          }
        )
      }
      routes.push(
        {
          exact: true,
          path: '/dashboards',
          Page: DashboardsListPage,
        },
        {
          exact: true,
          path: '/dashboards/:dashboardId',
          Page: DashboardViewPage,
        }
      )
    } else {
      routes.push({
        exact: true,
        path: '/dashboards',
        Page: DashboardsLandingPage,
      })
    }
  }

  if (isUserCompany) {
    if (userAddons?.registries.enabled) {
      routes.push(
        {
          exact: true,
          path: '/automations',
          Page: AutomationsListPage,
        },
        {
          exact: true,
          path: '/automations/create',
          Page: AutomationCreationPage,
        },
        {
          exact: true,
          path: '/automations/:automationId/edit',
          Page: AutomationEditionPage,
        }
      )
    } else {
      routes.push({
        exact: true,
        path: '/automations',
        Page: AutomationsLandingPage,
      })
    }
  }

  routes.push({
    exact: true,
    path: '/food-alerts',
    Page: RasffViewPage,
  })
  routes.push({
    exact: true,
    path: '/support',
    Page: SupportPage,
  })

  return routes
}
