import { Help } from '@/components/Help'
import { DocumentPicker, useDocumentPicker } from '@/features/documents'
import { EntryCreateInput, EntryFieldSet, useFileEntryHandler } from '@/features/entries'
import { useFeatureFlag } from '@/features/flags'
import { DirtyIssue } from '@/features/issues'
import { Card } from '@blockchain-traceability-sl/tailwind-components'
import { useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useInitialTaskData } from '../../hooks'
import { TaskCreation } from '../../interfaces'

type FormValues = Pick<TaskCreation, 'formId'> & {
  entries: Array<EntryCreateInput>
  issues: DirtyIssue[]
}

export const ViewEntries = () => {
  const { value: flagPaginateTasksEntries } = useFeatureFlag(
    'improve_the_performance_of_tasks_with_more_than_100_fields'
  )

  const params = useParams<{ taskId: string }>()

  const { task, documentIds, issues } = useInitialTaskData(params.taskId, {
    associationsEnabled: true,
  })

  const {
    showDocumentPicker,
    toggleShowDocumentPicker,
    documentsPicked,
    setDocumentsPicked,
    documentPickerTrigger,
    setDocumentPickerTrigger,
  } = useDocumentPicker<string>(documentIds)

  const { setFieldValue, setFieldTouched, values } = useFormikContext<FormValues>()
  const fileOptions = useFileEntryHandler({
    id: task?._id,
    entries: task?.entries,
    issues,
    setFieldValue,
    setFieldTouched,
  })

  const documentOptions = useMemo(
    () => ({
      documentsPicked,
      setDocumentPickerTrigger,
      toggleShowDocumentPicker,
    }),
    [documentsPicked, setDocumentPickerTrigger, toggleShowDocumentPicker]
  )

  return (
    <>
      <div className='space-y-6 m-1'>
        <Card className='overflow-visible' divideY={!flagPaginateTasksEntries}>
          <Card.Body>
            <div className='mt-5 md:mt-0'>
              <EntryFieldSet
                items={values.entries}
                fileOptions={fileOptions}
                documentOptions={documentOptions}
                paginationEnabled={flagPaginateTasksEntries}
              />
            </div>
          </Card.Body>
        </Card>

        <div className='flex justify-between'>
          <Help analyticsProperties={{ Source: 'Task edition' }} />
        </div>
      </div>
      <DocumentPicker
        show={showDocumentPicker}
        toggle={toggleShowDocumentPicker}
        onPick={documentFile => {
          setFieldValue(`${documentPickerTrigger}`, documentFile._id)
          setDocumentsPicked([...documentsPicked, documentFile])
          setDocumentPickerTrigger(null)
          toggleShowDocumentPicker()
        }}
      />
    </>
  )
}
