export interface CompanyMedia {
  logo?: string
  icon?: string
}

export enum CompanyExtension {
  STOCK = 'stock',
  FACILITIES = 'facilities',
}

export interface CompanyExtensions {
  [CompanyExtension.STOCK]: boolean
  [CompanyExtension.FACILITIES]: boolean
}

export interface CompanyLocalization {
  address: string
}

// Enable true with limit 0 is infinity
export interface CompanyAuthAddons {
  documents: {
    upload: { limit: number; enabled: boolean }
  }
  stakeHolders: {
    create: { limit: number; enabled: boolean }
  }
  products: {
    create: { limit: number; enabled: boolean }
  }
  stakeHolderProducts: { limit: number; enabled: boolean }
  receptions: { limit: number; enabled: boolean }
  trus: {
    create: { limit: number; enabled: boolean }
    send: { limit: number; enabled: boolean }
    find: { limit: number; enabled: boolean }
  }
  traceability: { limit: number; enabled: boolean }
  transparency: { limit: number; enabled: boolean }
  stockManagement: { limit: number; enabled: boolean }
  blockchain: { limit: number; enabled: boolean }
  e2eTraceability: { limit: number; enabled: boolean }
  registries: { limit: number; enabled: boolean }
  departments: { limit: number; enabled: boolean }
  users: { limit: number; enabled: boolean }
  facilities: { limit: number; enabled: boolean }
}

export interface Company {
  _id: string
  countryCode: string
  reference: string
  name: string
  permissionLevel: number
  usersCanSeeTasksInReview?: boolean

  media: CompanyMedia
  visibility: boolean
  extensions: CompanyExtensions
  localization: CompanyLocalization
  createdAt: string
  updatedAt: string
}

export interface CompanyCreation {
  countryCode: string
  reference: string
  name: string
  address: string
  email: string
  password: string
  language?: string
  timezone?: string
}

interface CompanyTheme {
  primaryColor?: string
}

interface CompanyUpdateMedia {
  icon?: { path: string; mimeType: string } | null
  logo?: { path: string; mimeType: string } | null
}

export interface CompanyUpdateMediaRaw {
  icon?: File | null
  logo?: File | null
}

export interface CompanyUpdate {
  name?: string
  theme?: CompanyTheme
  media?: CompanyUpdateMedia
  visibility?: boolean
  address?: string
}
