import { Help } from '@/components/Help'
import { StepOverview } from '@/components/StepOverview'
import { DocumentPicker, useDocumentPicker } from '@/features/documents'
import { EntryCreateInput, EntryFieldSet, useFileEntryHandler } from '@/features/entries'
import { useFeatureFlag } from '@/features/flags'
import { DirtyIssue } from '@/features/issues'
import useOnScreen from '@/hooks/use-on-screen'
import { useToggle } from '@/hooks/use-toggle'
import {
  ButtonWithIcon,
  Card,
  Step,
  Tooltip,
} from '@blockchain-traceability-sl/tailwind-components'
import { ArrowSmDownIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { FormikErrors, useFormikContext } from 'formik'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useInitialTaskData } from '../../hooks'
import { TaskCreation } from '../../interfaces'
import { scrollToEntryError } from '../../utils'

type FormValues = Pick<TaskCreation, 'formId'> & {
  entries: Array<EntryCreateInput>
  issues: DirtyIssue[]
}

export const StepTaskUpdateEntries = () => {
  const { value: flagPaginateTasksEntries } = useFeatureFlag(
    'improve_the_performance_of_tasks_with_more_than_100_fields'
  )

  const [allowNext, toggleAllowNext] = useToggle(true)

  const { t } = useTranslation('nsTaskEditionPage')
  const params = useParams<{ taskId: string }>()
  const [isActionsBoxOnScreen, setActionsBoxRef] = useOnScreen()

  const { task, documentIds, issues } = useInitialTaskData(params.taskId, {
    associationsEnabled: true,
  })

  const {
    showDocumentPicker,
    toggleShowDocumentPicker,
    documentsPicked,
    setDocumentsPicked,
    documentPickerTrigger,
    setDocumentPickerTrigger,
  } = useDocumentPicker<string>(documentIds)

  const { setTouched, setFieldValue, setFieldTouched, values } = useFormikContext<FormValues>()
  const fileOptions = useFileEntryHandler({
    id: task?._id,
    entries: task?.entries,
    issues,
    setFieldValue,
    setFieldTouched,
  })
  const handleNext = async (callbackFunction: Function) => {
    const stepErrors: FormikErrors<FormValues> = (await setTouched({
      entries: values.entries.map(() => ({
        value: true,
        values: true,
      })),
      issues: values.issues.map(issue => ({
        entries: issue.entries.map(() => ({
          value: true,
          values: true,
        })),
      })),
    })) as unknown as FormikErrors<FormValues>

    if (!stepErrors?.entries && !stepErrors?.issues) {
      callbackFunction()
    }

    // Scroll to first failed entry or issue entry
    scrollToEntryError(
      stepErrors.entries as FormikErrors<EntryCreateInput>[],
      stepErrors.issues as FormikErrors<DirtyIssue>[]
    )
  }

  const documentOptions = useMemo(
    () => ({
      documentsPicked,
      setDocumentPickerTrigger,
      toggleShowDocumentPicker,
    }),
    [documentsPicked, setDocumentPickerTrigger, toggleShowDocumentPicker]
  )

  const handleChangePage = (pageNumber: number, total: number) => {
    // Allow next if last page
    toggleAllowNext(pageNumber === total - 1)
  }

  return (
    <>
      <Step
        title={t('steps.step1.stepTitle')}
        subtitle={
          <div className='flex gap-3'>
            <span>{t('steps.step1.stepSubtitle')}</span>
            <Tooltip tip={t('steps.step1.description')} cursor>
              <span>
                <InformationCircleIcon className='w-4 h-4 text-gray-400' />
              </span>
            </Tooltip>
          </div>
        }
        overview={<StepOverview items={[t('steps.step1.overview')]} />}
      >
        {({ goNext }) => (
          <div className='space-y-6 m-1'>
            <Card className='overflow-visible' divideY={!flagPaginateTasksEntries}>
              <Card.Body>
                <div className='mt-5 md:mt-0'>
                  <EntryFieldSet
                    items={values.entries}
                    fileOptions={fileOptions}
                    documentOptions={documentOptions}
                    paginationEnabled={flagPaginateTasksEntries}
                    onPageChange={handleChangePage}
                  />
                </div>
              </Card.Body>
            </Card>

            <div className='flex justify-between'>
              <Help analyticsProperties={{ Source: 'Task edition' }} />
              {allowNext && (
                <>
                  {!isActionsBoxOnScreen && (
                    <div className='fixed lg:hidden bottom-0 flex items-center justify-center bg-gray-100 inset-x-0 gap-4 p-6 py-6'>
                      <ButtonWithIcon
                        IconComponent={ArrowSmDownIcon}
                        onClick={() => handleNext(goNext)}
                      >
                        {t('steps.step1.nextText')}
                      </ButtonWithIcon>
                    </div>
                  )}
                  <div className='flex items-start gap-4' ref={setActionsBoxRef}>
                    <ButtonWithIcon
                      IconComponent={ArrowSmDownIcon}
                      onClick={() => handleNext(goNext)}
                    >
                      {t('steps.step1.nextText')}
                    </ButtonWithIcon>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </Step>
      <DocumentPicker
        show={showDocumentPicker}
        toggle={toggleShowDocumentPicker}
        onPick={documentFile => {
          setFieldValue(`${documentPickerTrigger}`, documentFile._id)
          setDocumentsPicked([...documentsPicked, documentFile])
          setDocumentPickerTrigger(null)
          toggleShowDocumentPicker()
        }}
      />
    </>
  )
}
