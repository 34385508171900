import {
  useFetchStakeHolderProductsByIds,
  useStakeHolderProductsByIds,
} from '@/features/stakeHolderProducts'
import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useMemo, useState } from 'react'
import { useFetchReceptions, usePaginatedReceptions, useReceptionsByIds } from '../hooks'
import { ReceptionFilters } from '../interfaces'

export type ReceptionsSelectMultiFieldProps = Omit<SelectMultiFieldProps, 'options'> &
  Omit<ReceptionFilters, 'search'> & { receptionsIdsValue?: string[] }

export const ReceptionsSelectMultiField = ({
  stakeHolderId,
  stakeHolderProductId,
  receptionsIdsValue,
  ...props
}: ReceptionsSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [receptionsOptions, setReceptionsOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedReceptionsLoading,
    receptions,
    fetchNextPage,
  } = usePaginatedReceptions({
    filters: {
      search: debouncedSearch,
      stakeHolderId,
      stakeHolderProductId,
    },
  })

  const { stakeHolderProducts, isLoading: stakeHolderProductsIsLoading } =
    useStakeHolderProductsByIds(
      receptions.map(({ stakeHolderProductId }) => stakeHolderProductId),
      {
        enabled: !!receptions.length,
      }
    )
  const { fetchStakeHolderProductsByIds } = useFetchStakeHolderProductsByIds()

  const receptionsWithProductReference = useMemo(
    () =>
      receptions.map(reception => ({
        ...reception,
        reference:
          `${reception.reference} - ${
            stakeHolderProducts.find(product => product._id === reception.stakeHolderProductId)
              ?.stakeHolder.product.name || 'Loading...'
          }` || '',
      })),
    [receptions, stakeHolderProducts]
  )

  const { fetchReceptions } = useFetchReceptions({
    search: debouncedSearch,
    stakeHolderId,
    stakeHolderProductId,
  })

  const { receptions: receptionsValues, isLoading: receptionsValuesIsLoading } = useReceptionsByIds(
    receptionsIdsValue || [],
    {
      enabled: !!receptionsIdsValue,
    }
  )

  const [isLoading, setIsLoading] = useState(
    isPaginatedReceptionsLoading || receptionsValuesIsLoading
  )

  useEffect(() => {
    setReceptionsOptions(
      receptionsWithProductReference.map(reception =>
        createSelectorOption(reception.reference, reception._id)
      )
    )
  }, [receptions, receptionsWithProductReference])

  useEffect(() => {
    setIsLoading(
      isPaginatedReceptionsLoading || receptionsValuesIsLoading || stakeHolderProductsIsLoading
    )
  }, [isPaginatedReceptionsLoading, receptionsValuesIsLoading, stakeHolderProductsIsLoading])

  const receptionsValuesWithProductReference = useMemo(
    () =>
      receptionsValues.map(reception => ({
        ...reception,
        reference: `${reception.reference} - ${
          stakeHolderProducts.find(product => product._id === reception.stakeHolderProductId)
            ?.stakeHolder.product.name || 'Loading...'
        }`,
      })),
    [receptionsValues, stakeHolderProducts]
  )

  const value = receptionsValuesWithProductReference.length
    ? receptionsValuesWithProductReference.map(reception =>
        createSelectorOption(reception.reference, reception._id)
      )
    : props.value

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchReceptions()
      .then(receptions => {
        fetchStakeHolderProductsByIds(
          receptions.map(reception => reception.stakeHolderProductId)
        ).then(stakeHolderProducts => {
          const newOptions = receptions.map(reception => {
            const product = stakeHolderProducts.find(p => p._id === reception.stakeHolderProductId)
            return createSelectorOption(
              `${reception.reference} - ${product?.stakeHolder.product.name || 'Loading...'}`,
              reception._id
            )
          })
          setReceptionsOptions(newOptions)
          if (props.onChange) {
            props.onChange(newOptions)
          }
        })
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  return (
    <SelectMultiField
      {...props}
      value={value}
      isLoading={isLoading}
      options={receptionsOptions}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
