import { useFetchProductsByIds, useProductsByIds } from '@/features/products'
import { useDebounceState } from '@/hooks/use-debounce-state'
import {
  SelectMultiField,
  SelectMultiFieldProps,
  SelectorOption,
  createSelectorOption,
  noop,
} from '@blockchain-traceability-sl/tailwind-components'
import { useEffect, useMemo, useState } from 'react'
import { useFetchTRUs, usePaginatedTRUs, useTRUsByIds } from '../hooks'
import { ITRUFilters } from '../interfaces'

type TrusSelectMultiFieldProps = Omit<SelectMultiFieldProps, 'options'> &
  Omit<ITRUFilters, 'search'> & {
    truIdsValue?: string[]
  }

export const TrusSelectMultiField = ({
  productId,
  originId,
  truIdsValue,
  ...props
}: TrusSelectMultiFieldProps) => {
  const [search, setSearch] = useState<string | undefined>()
  const [debouncedSearch] = useDebounceState(search)
  const [options, setOptions] = useState<SelectorOption[]>([])

  const {
    isLoading: isPaginatedTRUsLoading,
    trus,
    fetchNextPage,
  } = usePaginatedTRUs({
    filters: { search: debouncedSearch, productId, originId },
  })

  const { trus: truValues, isLoading: truValuesLoading } = useTRUsByIds(truIdsValue || [], {
    enabled: !!truIdsValue?.length,
  })

  const { products, isLoading: productsLoading } = useProductsByIds(
    trus.map(({ productId }) => productId),
    {
      enabled: !!trus.length,
    }
  )

  const trusWithProductReference = useMemo(
    () =>
      trus.map(tru => ({
        ...tru,
        reference:
          `${tru.reference} - ${
            products.find(product => product._id === tru.productId)?.name || 'Loading...'
          }` || '',
      })),
    [trus, products]
  )

  const { fetchTRUs } = useFetchTRUs({
    search: debouncedSearch,
    productId,
    originId,
  })

  const { fetchProductsByIds } = useFetchProductsByIds()

  const [isLoading, setIsLoading] = useState(isPaginatedTRUsLoading || truValuesLoading)

  useEffect(() => {
    setOptions(trusWithProductReference.map(tru => createSelectorOption(tru.reference, tru._id)))
  }, [debouncedSearch, trusWithProductReference])

  useEffect(() => {
    setIsLoading(
      isPaginatedTRUsLoading || (!!truIdsValue?.length && truValuesLoading) || productsLoading
    )
  }, [isPaginatedTRUsLoading, productsLoading, truIdsValue?.length, truValuesLoading])

  const handleSelectAll = async () => {
    setIsLoading(true)
    fetchTRUs()
      .then(trus => {
        fetchProductsByIds(trus.map(tru => tru.productId)).then(products => {
          const trusWithProductReference = trus.map(tru => ({
            ...tru,
            reference: `${tru.reference} - ${
              products.find(p => p._id === tru.productId)?.name || 'Loading...'
            }`,
          }))
          const newOptions = trusWithProductReference.map(tru =>
            createSelectorOption(tru.reference, tru._id)
          )
          setOptions(newOptions)
          if (props.onChange) {
            props.onChange(newOptions)
          }
        })
      })
      .catch(noop)
      .finally(() => setIsLoading(false))
  }

  const truValuesWithProductReference = useMemo(
    () =>
      truValues.map(tru => ({
        ...tru,
        reference: `${tru.reference} - ${
          products.find(product => product._id === tru.productId)?.name || 'Loading...'
        }`,
      })),
    [truValues, products]
  )

  const values = truValuesWithProductReference.length
    ? truValuesWithProductReference.map(({ _id, reference }) =>
        createSelectorOption(reference, _id)
      )
    : props.value

  return (
    <SelectMultiField
      {...props}
      value={values}
      isLoading={isLoading}
      options={options}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
      onSelectAll={handleSelectAll}
    />
  )
}
